<template>
    <div
        :style="$vuetify.breakpoint.mobile? 'width:100%;':'width:440px; height: calc(100vh - 92px);'"
    >
        <!-- # 문구 -->
        <div
            v-if="!$store.state.client_user.is_logined"
            class="pt-1 mt-1 pl-5 mb-0"
            style="font-size:14px; font-weight:500; height:29px; color:#ef6d6d;"
        >
            로그인을 해야 즐겨찾기 정보가 저장됩니다
        </div>

        <!-- # 검색 -->
        <div
            class="px-2 mt-0 mobile"
            :class="$store.state.client_user.is_logined? 'mt-2':''"
        >
            <v-text-field
                hide-details="auto"
                dense
                outlined
                class="rounded-15"
                color="primary"
                placeholder="검색"
                v-model="keyword"
                @input="keyword = keyword.toUpperCase(); search()"
                @key.enter="search()"
            >
                <template v-slot:prepend-inner>
                    <v-icon class="mr-1 mt-1px" size="18" color="#424361">mdi-magnify</v-icon>
                </template>
            </v-text-field>
        </div>


        <!-- # 목록 상단 -->
        <div
            class="d-flex alter_black--text text-body-2 mt-1 pb-2 mb-2"
            :style="{ paddingRight: scrollBarWidth + 'px' }"
            style="border-bottom:1px solid #e0e0e0; height:29px;"
        >
            <!-- # 국가 -->
            <div
                style="width:32%"
                class="pl-3"
            >
                국가
            </div>

            <!-- # 환율 -->
            <div
                v-if="$store.state.list_tab == '통화' || $store.state.list_tab == '암호화폐'"
                style="width:46%"
                class="pl-1 d-flex justify-space-between pr-4"
            >
                환율
            </div>

            <!-- # 변동율 -->
            <div
                v-if="$store.state.list_tab == '통화' || $store.state.list_tab == '암호화폐'"
                style="width:26%"
                class="pl-0"
            >
                변동율
            </div>

            <!-- # 보기 방식 선택 (즐겨찾기) -->
            <div
                v-if="$store.state.list_tab == '즐겨찾기'"
                style="width:72%; margin-top:-8px;"
                class="d-flex pl-2"
            >
                <v-checkbox
                    style="width:113px;"
                    dense
                    hide-details
                    label="현재 환율"
                    v-model="is_exchange"
                ></v-checkbox>
                <v-checkbox
                    dense
                    hide-details
                    label="사용자지정 환율"
                    v-model="is_custom"
                ></v-checkbox>
            </div>
        </div>

        <!-- # 목록 (통화 & 암호화폐) -->
        <div
            v-if="$store.state.list_tab == '통화' || $store.state.list_tab == '암호화폐'"
            :style="$vuetify.breakpoint.mobile? 'height:78vh;':'height: calc(100% - 80px);'"
            style="overflow-y:scroll;"
        >
            <draggable
                v-model="list"
                ghost-class="ghost"
                @start="onStart"
                @end="onEnd"
                :disabled="is_search"
                :delay="100"
            >
                <div
                    v-for="item in is_search? search_list : list" :key="item.code"
                    class="list-item d-flex alter_black--text text-body-2"
                    style="cursor:grab; user-select: none; padding: 10px 8px"
                >
                    <!-- # 국가 -->
                    <div
                        style="width:32%"
                        class="pl-1 d-flex align-center"
                    >
                        <!-- 이미지 -->
                        <v-sheet
                            width="34"
                            height="34"
                        >
                            <!-- 통화 -->
                            <v-img
                                v-if="item.type == 'currency'"
                                class="rounded-circle"
                                style="box-shadow:0px 0px 4px #ccc;"
                                :src="require('@/assets/flags/'+item.flag)"
                            ></v-img>

                            <!-- 암호화폐 -->
                            <v-img
                                v-if="item.type == 'coin'"
                                class="rounded-circle"
                                style="box-shadow:0px 0px 4px #ccc;"
                                :src="require('@/assets/coins/'+item.flag)"
                            ></v-img>
                        </v-sheet>

                        <!-- 글자 -->
                        <div
                            class="ml-6px"
                        >
                            <!-- 한글명 -->
                            <p
                                class="alter_black--text mb-0"
                                style="letter-spacing:-0.4px;"
                            >
                                {{item.name}}
                            </p>

                            <!-- 코드명 -->
                            <p
                                class="alter_grey--text font-weight-bold mb-0"
                                style="margin-top:-2px;"
                            >
                                {{item.code}}
                            </p>
                        </div>
                    </div>

                    <!-- # 환율 -->
                    <div
                        style="width:46%;"
                        class="pl-1 pr-2px font-weight-medium custom_exchange2"
                    >
                        <p
                            class="mb-0"
                            :class="item.custom != 0? 'grey--text text--lighten-1 font-weight-regular':''"
                        >
                            {{item.symbol}}1 <span class="operator--text font-weight-medium mx-1">=</span> ₩ {{$toComma(item.exchange)}}
                        </p>
                        <div
                            class="d-flex"
                        >
                            <p
                                class="mb-0 pr-1"
                                :class="item.custom == 0? 'grey--text text--lighten-1 font-weight-regular':'primary--text'"
                                style="font-size:12px; letter-spacing:-0.4px;"
                            >
                                사용자지정 ₩
                            </p>
                            <v-text-field
                                style="width:100px;"
                                class="shrink ma-0 pa-0 custom-text-color"
                                solo
                                flat
                                dense
                                hide-details
                                :value="getFormattedCustom(item)"
                                @input="updateCustom(item, $event)"
                            ></v-text-field>
                        </div>
                    </div>

                    <!-- # 변동율 -->
                    <div
                        style="width:26%"
                        class="pl-0 d-flex align-center pr-2px"
                    >
                        <v-chip
                            :color="item.rate < 0? '#60a5fa':'#f87171'"
                            small
                        >
                            <v-icon v-if="item.rate < 0" color="white" size="10" class="mr-1">mdi-triangle-down</v-icon>
                            <v-icon v-else color="white" size="10" class="mr-1">mdi-triangle</v-icon>

                            <span style="color:white; font-weight:300;">{{Math.abs(item.rate).toFixed(2)}}%</span>
                        </v-chip>

                        <div
                            v-if="$store.state.client_user.is_logined"
                        >
                            <v-icon v-if="!item.is_bookmarked" size="20" class="ml-2" @click="item.is_bookmarked = true; add_bookmark(item.name);">mdi-star-outline</v-icon>
                            <v-icon v-else size="20" class="ml-2" color="primary" @click="item.is_bookmarked = false; remove_bookmark(item.name);">mdi-star</v-icon>
                        </div>
                        <div
                            v-else
                        >
                            <v-icon size="20" class="ml-2" @click="not_logined()">mdi-star-outline</v-icon>
                        </div>
                    </div>
                </div>
            </draggable>
        </div>

        <!-- # 목록 (즐겨찾기) -->
        <div
            v-if="$store.state.list_tab == '즐겨찾기'"
            :style="$vuetify.breakpoint.mobile? 'height:78vh;':'height: calc(100% - 80px);'"
            style="overflow-y:scroll;"
        >
            <!-- # 한국 -->
            <div
                v-for="item in oneList" :key="item.code"
                class="list-item d-flex px-2 alter_black--text text-body-2"
                style="cursor:grab;"
            >
                <!-- # 국가 -->
                <div
                    style="width:30%"
                    class="pl-1 d-flex align-center"
                >
                    <!-- 이미지 -->
                    <v-sheet
                        width="36"
                        height="36"
                    >
                        <!-- 통화 -->
                        <v-img
                            v-if="item.type == 'currency'"
                            class="rounded-circle"
                            style="box-shadow:0px 0px 4px #ccc;"
                            :src="require('@/assets/flags/'+item.flag)"
                        ></v-img>

                        <!-- 암호화폐 -->
                        <v-img
                            v-if="item.type == 'coin'"
                            class="rounded-circle"
                            style="box-shadow:0px 0px 4px #ccc;"
                            :src="require('@/assets/coins/'+item.flag)"
                        ></v-img>
                    </v-sheet>

                    <!-- 글자 -->
                    <div
                        class="ml-2"
                    >
                        <!-- 한글명 -->
                        <p
                            class="alter_black--text mb-0"
                        >
                            {{item.name}}
                        </p>

                        <!-- 코드명 -->
                        <p
                            class="alter_grey--text font-weight-bold mb-0"
                            style="margin-top:-2px;"
                        >
                            {{item.code}}
                        </p>
                    </div>
                </div>

                <!-- # 숫자 & 즐겨찾기 -->
                <div
                    style="width:70%;"
                    class="d-flex pl-0 pr-2px font-weight-medium align-center"
                >
                    <!-- 숫자 -->
                    <div
                        class="flex-grow-1 pr-2"
                    >
                        <p class="text-right alter_black--text text-subtitle-1 font-weight-bold mb-1">
                            {{item.symbol}} {{$toComma($math.divide($store.state.result, (item.exchange_type == '현재 환율'? item.exchange: item.custom)).toFixed(2)).split('.')[0]}}<span style="font-weight:500;">.{{$toComma($math.divide($store.state.result, item.exchange).toFixed(2)).split('.')[1]}}</span>
                        </p>
                    </div>

                    <!-- 즐겨찾기 -->
                    <v-icon
                        size="20"
                        class="ml-2"
                        color="primary"
                    >
                        mdi-star
                    </v-icon>
                </div>
            </div>
            <draggable
                v-model="list"
                ghost-class="ghost"
                @start="onStart"
                @end="onEnd"
                :disabled="is_search"
                :delay="100"
            >
                <div
                    v-for="item in filteredList" :key="item.code"
                    class="list-item d-flex px-2 alter_black--text text-body-2 mt-"
                    style="cursor:grab;"
                >
                    <!-- # 국가 -->
                    <div
                        style="width:30%"
                        class="pl-1 d-flex align-center"
                    >
                        <!-- 이미지 -->
                        <v-sheet
                            width="36"
                            height="36"
                        >
                            <!-- 통화 -->
                            <v-img
                                v-if="item.type == 'currency'"
                                class="rounded-circle"
                                style="box-shadow:0px 0px 4px #ccc;"
                                :src="require('@/assets/flags/'+item.flag)"
                            ></v-img>

                            <!-- 암호화폐 -->
                            <v-img
                                v-if="item.type == 'coin'"
                                class="rounded-circle"
                                style="box-shadow:0px 0px 4px #ccc;"
                                :src="require('@/assets/coins/'+item.flag)"
                            ></v-img>
                        </v-sheet>

                        <!-- 글자 -->
                        <div
                            class="ml-2"
                        >
                            <!-- 한글명 -->
                            <p
                                class="alter_black--text mb-0"
                            >
                                {{item.name}}
                            </p>

                            <!-- 코드명 -->
                            <p
                                class="alter_grey--text font-weight-bold mb-0"
                                style="margin-top:-2px;"
                            >
                                {{item.code}}
                            </p>
                        </div>
                    </div>

                    <!-- # 숫자 & 즐겨찾기 -->
                    <div
                        style="width:70%;"
                        class="d-flex pl-0 pr-2px font-weight-medium align-center"
                    >
                        <!-- 숫자 -->
                        <div
                            class="flex-grow-1 pr-2"
                        >
                            <p class="text-right alter_black--text text-subtitle-1 font-weight-bold mb-0">
                                {{item.symbol}} {{$toComma($math.divide($store.state.result, (item.exchange_type == '현재 환율'? item.exchange: item.custom)).toFixed(2)).split('.')[0]}}<span style="font-weight:500;">.{{$toComma($math.divide($store.state.result, item.exchange).toFixed(2)).split('.')[1]}}</span>
                            </p>
                            <div
                                class="d-flex custom_exchange2 align-center"
                                style="margin-top:-2px;"
                            >
                                <v-radio-group
                                    row
                                    v-model="item.exchange_type"
                                    hide-details
                                    class="mt-0 mb-0 pt-0 pb-0 d-flex mx-0 px-0"
                                    style="height:20px; width:152px !important;"
                                >
                                    <v-radio
                                        style="width:98px;"
                                        dense
                                        hide-details
                                        :label="item.symbol + ' ' + $toComma(item.exchange)"
                                        value="현재 환율"
                                    ></v-radio>
                                    <v-radio
                                        style="width:20px;"
                                        dense
                                        hide-details
                                        value="사용자지정 환율"
                                        :label="'₩'"
                                        :class="{'active-radio': item.exchange_type === '사용자지정 환율'}"
                                    ></v-radio>
                                </v-radio-group>
                                <v-text-field
                                    :class="{
                                        'custom-text-color-grey': item.exchange_type == '현재 환율',
                                        'custom-text-color': item.exchange_type == '사용자지정 환율'
                                    }"
                                    style="position:relative; width:90px;"
                                    class="shrink ma-0 pa-0"
                                    solo
                                    flat
                                    dense
                                    hide-details
                                    placeholder="사용자지정"
                                    :value="getFormattedCustom(item)"
                                    @input="updateCustom(item, $event)"
                                ></v-text-field>
                            </div>
                        </div>

                        <!-- 즐겨찾기 -->
                        <div
                            v-if="$store.state.client_user.is_logined"
                        >
                            <v-icon v-if="!item.is_bookmarked" size="20" class="ml-2" @click="item.is_bookmarked = true; add_bookmark(item.name);">mdi-star-outline</v-icon>
                            <v-icon v-else size="20" class="ml-2" color="primary" @click="item.is_bookmarked = false; remove_bookmark(item.name);">mdi-star</v-icon>
                        </div>
                        <div
                            v-else
                        >
                            <v-icon size="20" class="ml-2" @click="not_logined()">mdi-star-outline</v-icon>
                        </div>
                    </div>
                </div>
            </draggable>
        </div>

        <!-- <v-icon
            size="36"
            class="d-flex mx-auto mt-6"
            color="alter_grey lighten-2"
        >
            mdi-cancel
        </v-icon>
        <p
            class="text-caption alter_grey--text text-center mt-2"
        >
            암호화폐 목록이 없습니다
        </p> -->
    </div>
</template>
<script>
import draggable from 'vuedraggable'

export default {
    components: {
        draggable,
    },

    data: () => ({
        select_list: {
            translate: [
                "KR 한국어",
                "EN 영어"
            ]
        },

        drag: false,
        list: [],
        search_list: [],
        bookmark_list: [],

        translate: "KR 한국어",

        original_list: [
            { exchange_type: "현재 환율", custom: 0, type: "currency", flag: "us.svg", chosung: "ㅁㄱ", name: "미국", code: "USD", symbol: "$", exchange: 1339.60, rate: 0.04, is_bookmarked: false },
            { exchange_type: "현재 환율", custom: 0, type: "currency", flag: "eu.svg", chosung: "ㅇㄹ", name: "유로", code: "EUR", symbol: "€", exchange: 1480.46, rate: 0.05, is_bookmarked: false },
            { exchange_type: "현재 환율", custom: 0, type: "currency", flag: "jp.svg", chosung: "ㅇㅂ", name: "일본", code: "JPY", symbol: "¥", exchange: 916.56, rate: 0.03, is_bookmarked: false },
            { exchange_type: "현재 환율", custom: 0, type: "currency", flag: "cn.svg", chosung: "ㅈㄱ", name: "중국", code: "CNY", symbol: "¥", exchange: 187.80, rate: 0.38, is_bookmarked: false },
            { exchange_type: "현재 환율", custom: 0, type: "currency", flag: "gb.svg", chosung: "ㅇㄱ", name: "영국", code: "GBP", symbol: "£", exchange: 1745.56, rate: 0.61, is_bookmarked: false },
            { exchange_type: "현재 환율", custom: 0, type: "currency", flag: "au.svg", chosung: "ㅎㅈ", name: "호주", code: "AUD", symbol: "$", exchange: 902.49, rate: 0.50, is_bookmarked: false },

            { exchange_type: "현재 환율", custom: 0, type: "currency", flag: "ca.svg", chosung: "ㅋㄴㄷ", name: "캐나다", code: "CAD", symbol: "$", exchange: 984.53, rate: 0.70, is_bookmarked: false },
            { exchange_type: "현재 환율", custom: 0, type: "currency", flag: "nz.svg", chosung: "ㄴㅈㄹㄷ", name: "뉴질랜드", code: "NZD", symbol: "$", exchange: 822.18, rate: 0.31, is_bookmarked: false },
            { exchange_type: "현재 환율", custom: 0, type: "currency", flag: "th.svg", chosung: "ㅌㄱ", name: "태국", code: "THB", symbol: "฿", exchange: 39.03, rate: 0.23, is_bookmarked: false },
            { exchange_type: "현재 환율", custom: 0, type: "currency", flag: "vn.svg", chosung: "ㅂㅌㄴ", name: "베트남", code: "VND", symbol: "₫", exchange: 5.37, rate: 0.56, is_bookmarked: false },
            { exchange_type: "현재 환율", custom: 0, type: "currency", flag: "hk.svg", chosung: "ㅎㅋ", name: "홍콩", code: "HKD", symbol: "$", exchange: 171.91, rate: 0.56, is_bookmarked: false },
            { exchange_type: "현재 환율", custom: 0, type: "currency", flag: "tw.svg", chosung: "ㄷㅁ", name: "대만", code: "TWD", symbol: "NT$", exchange: 41.96, rate: 0.55, is_bookmarked: false },

            { exchange_type: "현재 환율", custom: 0, type: "currency", flag: "ch.svg", chosung: "ㅅㅇㅅ", name: "스위스", code: "CHF", symbol: "CHF", exchange: 1566.93, rate: 0.47, is_bookmarked: false },
            { exchange_type: "현재 환율", custom: 0, type: "currency", flag: "se.svg", chosung: "ㅅㅇㄷ", name: "스웨덴", code: "SEK", symbol: "kr", exchange: 130.87, rate: 0.31, is_bookmarked: false },
            { exchange_type: "현재 환율", custom: 0, type: "currency", flag: "in.svg", chosung: "ㅇㄷ", name: "인도", code: "INR", symbol: "₹", exchange: 15.97, rate: 0.44, is_bookmarked: false },
            { exchange_type: "현재 환율", custom: 0, type: "currency", flag: "ru.svg", chosung: "ㄹㅅㅇ", name: "러시아", code: "RUB", symbol: "₽", exchange: 14.59, rate: -0.14, is_bookmarked: false },
            { exchange_type: "현재 환율", custom: 0, type: "currency", flag: "br.svg", chosung: "ㅂㄹㅈ", name: "브라질", code: "BRL", symbol: "R$", exchange: 244.51, rate: 0.62, is_bookmarked: false },
            { exchange_type: "현재 환율", custom: 0, type: "currency", flag: "mx.svg", chosung: "ㅁㅅㅋ", name: "멕시코", code: "MXN", symbol: "$", exchange: 70.72, rate: 0.96, is_bookmarked: false },

            { exchange_type: "현재 환율", custom: 0, type: "currency", flag: "tr.svg", chosung: "ㅌㅋ", name: "터키", code: "TRY", symbol: "₺", exchange: 39.50, rate: 0.59, is_bookmarked: false },
            { exchange_type: "현재 환율", custom: 0, type: "currency", flag: "no.svg", chosung: "ㄴㄹㅇㅇ", name: "노르웨이", code: "NOK", symbol: "kr", exchange: 127.40, rate: 0.47, is_bookmarked: false },
            { exchange_type: "현재 환율", custom: 0, type: "currency", flag: "il.svg", chosung: "ㅇㅅㄹㅇ", name: "이스라엘", code: "ILS", symbol: "₪", exchange: 359.46, rate: -0.33, is_bookmarked: false },
            { exchange_type: "현재 환율", custom: 0, type: "currency", flag: "my.svg", chosung: "ㅁㄹㅇㅅㅇ", name: "말레이시아", code: "MYR", symbol: "RM", exchange: 305.82, rate: 0.54, is_bookmarked: false },
            { exchange_type: "현재 환율", custom: 0, type: "currency", flag: "hu.svg", chosung: "ㅎㄱㄹ", name: "헝가리", code: "HUF", symbol: "Ft", exchange: 3.79, rate: 0.53, is_bookmarked: false },
            { exchange_type: "현재 환율", custom: 0, type: "currency", flag: "pl.svg", chosung: "ㅍㄹㄷ", name: "폴란드", code: "PLN", symbol: "zł", exchange: 347.47, rate: 0.28, is_bookmarked: false },

            // 암호화폐
            { exchange_type: "현재 환율", custom: 0, type: "coin", flag: "비트코인.png", chosung: "ㅂㅌㅋㅇ", name: "비트코인", code: "BTC", symbol: "Ƀ", exchange: 81100000, rate: 0.43, is_bookmarked: false },
            { exchange_type: "현재 환율", custom: 0, type: "coin", flag: "리플.png", chosung: "ㄹㅍ", name: "리플", code: "XRP", symbol: "XRP", exchange: 816.4, rate: 0.53, is_bookmarked: false },
            { exchange_type: "현재 환율", custom: 0, type: "coin", flag: "솔라나.png", chosung: "ㅅㄹㄴ", name: "솔라나", code: "SOL", symbol: "SOL", exchange: 193450, rate: -0.67, is_bookmarked: false },
            { exchange_type: "현재 환율", custom: 0, type: "coin", flag: "이더리움.png", chosung: "ㅇㄷㄹㅇ", name: "이더리움", code: "ETH", symbol: "Ξ", exchange: 3526000, rate: 0.11, is_bookmarked: false },
            { exchange_type: "현재 환율", custom: 0, type: "coin", flag: "엘프.png", chosung: "ㅇㅍ", name: "엘프", code: "ELF", symbol: "ELF", exchange: 530.0, rate: -0.93, is_bookmarked: false },
            { exchange_type: "현재 환율", custom: 0, type: "coin", flag: "테더.png", chosung: "ㅌㄷ", name: "테더", code: "USDT", symbol: "₮", exchange: 1365, rate: -0.22, is_bookmarked: false },
            { exchange_type: "현재 환율", custom: 0, type: "coin", flag: "도지코인.png", chosung: "ㄷㅈㅋㅇ", name: "도지코인", code: "DOGE", symbol: "Ð", exchange: 142.0, rate: 0.92, is_bookmarked: false },
            { exchange_type: "현재 환율", custom: 0, type: "coin", flag: "에이다.png", chosung: "ㅇㅇㄷ", name: "에이다", code: "ADA", symbol: "₳", exchange: 480.9, rate: 2.43, is_bookmarked: false },
            { exchange_type: "현재 환율", custom: 0, type: "coin", flag: "트론.png", chosung: "ㅌㄹ", name: "트론", code: "TRX", symbol: "TRX", exchange: 224.4, rate: 1.54, is_bookmarked: false },
        ],

        oneList: [
            {
                exchange_type: "현재 환율",
                custom: 0,
                type: "currency",
                flag: "kr.svg",
                chosung: "ㅎㄱ",
                name: "한국",
                code: "KOR",
                symbol: "￦",
                exchange: 1,
            },
        ],

        keyword: "",
        is_search: false,

        is_exchange : true,
        is_custom : true

    }),

    computed: {
        scrollBarWidth() {
            return window.innerWidth - document.documentElement.clientWidth
        },

        filteredList() {
            const targetList = this.is_search ? this.search_list : this.list
            
            if (this.is_exchange && this.is_custom) {
                return targetList
            } else if (this.is_exchange) {
                return targetList.filter(item => item.exchange_type === "현재 환율")
            } else if (this.is_custom) {
                return targetList.filter(item => item.exchange_type === "사용자지정 환율") 
            } else {
                return targetList.filter(item => false)
            }
        }
    },

    watch: {
        '$store.state.list_tab': {
            handler(newValue) {
                this.keyword = ""

                if (newValue == "통화") {
                    this.list = this.original_list.filter(e => e.type == "currency")
                } else if (newValue == "암호화폐") {
                    this.list = this.original_list.filter(e => e.type == "coin")
                } else if (newValue == "즐겨찾기") {
                    this.list = this.original_list.filter(e => this.bookmark_list.includes(e.name))
                }
            },
            immediate: true
        },

        keyword(){
            if(this.keyword == ""){
                this.is_search = false
            }
        },

        is_exchange(){
            if(this.$store.state.list_tab == "즐겨찾기"){
                this.list = tempList.filter(e => this.bookmark_list.includes(e.name) && e.exchange_type == "현재 환율")
            }
        }
    },

    mounted() {
        this.loadBookmark()
    },

    methods: {
        getFormattedCustom(item) {
            // 숫자를 로컬 문자열로 변환하여 콤마를 포함시킵니다.
            return this.$toComma(item.custom)
        },

        updateCustom(item, value) {
            // 입력값에서 모든 콤마를 제거하고 숫자로 변환합니다.
            const numericValue = parseFloat(value.replace(/,/g, ''))
            item.custom = isNaN(numericValue) ? 0 : numericValue
        },

        // 목록 불러오기
        load(){
            this.is_search = false
            let tempList
            tempList = this.original_list.map(item => {
                item.is_bookmarked = this.bookmark_list.includes(item.name)
                return item
            }).sort((a, b) => b.is_bookmarked - a.is_bookmarked)

            if(this.$store.state.list_tab == "통화"){
                this.list = tempList.filter(e => e.type == "currency")
            }
            else if(this.$store.state.list_tab == "암호화폐"){
                this.list = tempList.filter(e => e.type == "coin")
            }
            else if(this.$store.state.list_tab == "즐겨찾기"){
                this.list = tempList.filter(e => this.bookmark_list.includes(e.name))
            }
        },

        // 검색
        search(){
            let tempList
            tempList = this.original_list.map(item => {
                item.is_bookmarked = this.bookmark_list.includes(item.name)
                return item
            }).sort((a, b) => b.is_bookmarked - a.is_bookmarked)

            tempList = tempList.filter(e => 
                e.name.includes(this.keyword)
                || e.chosung.includes(this.keyword)
                || e.code.includes(this.keyword.toUpperCase())
            )

            this.is_search = true

            if(this.$store.state.list_tab == "통화"){
                this.search_list = tempList.filter(e => e.type == "currency")
            }
            else if(this.$store.state.list_tab == "암호화폐"){
                this.search_list = tempList.filter(e => e.type == "coin")
            }
            else if(this.$store.state.list_tab == "즐겨찾기"){
                this.search_list = tempList.filter(e => this.bookmark_list.includes(e.name))
            }
        },

        // 즐겨찾기 불러오기
        loadBookmark(){
            this.$http.post("/api/bookmark/select/specific", {
                params: {
                    user_id: this.$store.state.client_user.user_id
                }
            }).then((res) => {
                if(res.data.length){
                    this.bookmark_list = res.data.map(e => e.name)
                }
                this.load()
            })
        },

        // 즐겨찾기 추가
        add_bookmark(name){
            this.bookmark_list.push(name)

            this.$http.post("/api/bookmark/insert", {
                params: {
                    user_id: this.$store.state.client_user.user_id,
                    name: name
                }
            })
        },

        // 즐겨찾기 삭제
        remove_bookmark(name){
            this.bookmark_list = this.bookmark_list.filter(e => e != name)

            this.$http.post("/api/bookmark/delete", {
                params: {
                    user_id: this.$store.state.client_user.user_id,
                    name: name
                }
            })
        },

        not_logined(){
            alert("즐겨찾기 기능은 로그인 이후 이용 가능합니다")
        },

        // ! 드래그 시작 (검토 필요)
        onStart() {
            // this.isDragging = true
        },

        // ! 드래그 종료 (검토 필요)
        onEnd() {
            // this.isDragging = false
            // this.draggedElement = null
        }
    }
}
</script>
<style scoped>
/* # 언어변경 (v-select) */
::v-deep .mobile .v-select fieldset {
    height:40px;
    min-height: 33px;
}

::v-deep .mobile .v-select .v-input__slot {
    height: 33px;
    min-height: 33px;
}

::v-deep .mobile .v-select .v-select__slot {
    font-size: 12px;
    height: 33px;
}

::v-deep .mobile .v-select .v-select__selections {
    height: 33px;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

/* # 검색 (v-text-field) */
::v-deep .mobile .v-text-field fieldset {
    min-height: 33px;
    height:40px;
}

::v-deep .mobile .v-text-field .v-text-field__slot {
    font-size: 12px;
    height: 33px;
    background: transparent !important;
}

/* # 사용자지정 (v-text-field) */
::v-deep .custom_exchange .v-text-field fieldset {
    min-height: 33px;
    height:40px;
}

::v-deep .custom_exchange .v-text-field .v-text-field__slot {
    font-size: 12px;
    height: 33px;
    margin-left:-12px;
    background: transparent !important;
}

/* # 드래그 관련 */
.list-item {
    padding: 6px 0;
    border-bottom:4px;
}

.ghost {
    position: relative;
}

.ghost::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 2px;
    background-color: #0369A1;
    pointer-events: none;
}

::v-deep .text-field-transparent .v-input__slot {
    background: transparent !important;
}

/* # v-checkbox */
::v-deep .v-input--selection-controls.v-input--dense .v-label {
    margin-left:-6px;
    margin-bottom:2px;
    font-size: 12px
}

/* # v-radio */
::v-deep .v-radio .v-icon {
    font-size: 18px;    
}

::v-deep .v-radio .v-label {
    font-size: 12px;
    margin-left: -6px;
}

/* v-radio 내부 패딩 조절 */
::v-deep .v-radio {
    padding: 0;
}

/* v-radio ripple 효과 위치 조절 */
::v-deep .v-radio .v-input--selection-controls__ripple {
    width:20px;
    height:20px;
    left: -5px;
    top: calc(50% - 17px);
}
</style>

<style scoped>
/* # 사용자지정2 (v-text-field) */
::v-deep .custom_exchange2 .v-text-field {
    min-height: 16px;
    height: 16px;
    padding: 0;
    border: none;
}

::v-deep .custom_exchange2 .v-text-field fieldset {
    min-height: 0;
    height: 0;
    border: none;
}

/* 모바일 화면 (600px 이하) */
@media (max-width: 600px) {
    ::v-deep .custom_exchange2 .v-text-field .v-text-field__slot {
        width: 60px;
    }
}

/* 데스크톱 화면 (600px 초과) */
@media (min-width: 601px) {
    ::v-deep .custom_exchange2 .v-text-field .v-text-field__slot {
        width: 100px;
    }
}

::v-deep .custom_exchange2 .v-text-field .v-text-field__slot {
    margin-top:2px;
    font-size: 12px;
    height: 16px;
    line-height: 16px; /* 텍스트 라인 높이 조정 */
    margin-left: -14px;
    padding: 0;
    background: transparent !important;
}

::v-deep .custom_exchange2 .v-text-field .v-label {
    display: none; /* 라벨 숨기기 */
}

::v-deep .custom_exchange2 .v-text-field .v-input__control {
    min-height: 16px;
    height: 16px;
}

::v-deep .custom_exchange2 .v-text-field .v-input__slot {
    min-height: 16px;
    height: 16px;
}

/* 사용자 지정 입력 필드 글자색 */
::v-deep .custom-text-color input {
    color: #0369A1 !important;
}

::v-deep .custom-text-color-grey input {
    color: #898989 !important;
    font-weight: 400;
}
</style>

<style scoped>
/* 라디오박스 > 활성화시에만 라벨 색 변경 */
::v-deep .active-radio .v-label {
    color: #0369A1 !important;
}
</style>

<style scoped>
.bottom-half {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: scroll;
}
</style>
<template>
    <div>
        <!-- 제목 -->
        <v-sheet outlined class="d-flex align-center ma-2 px-4" height="60">
            <font class="font-weight-bold text-h6 grey--text text--darken-2">회원 관리</font>
        </v-sheet>

        <!-- 본문 -->
        <v-sheet outlined class="ma-2 pa-2">
            <!-- 검색 -->
            <v-sheet outlined class="pa-2 mb-4">
                <v-sheet class="d-flex" width="660">
                    <!-- 유형 -->
                    <v-sheet width="180" class="mr-3">
                        <v-select
                            outlined
                            dense
                            hide-details
                            :items="select_list.search_type"
                            v-model="search_type"
                        ></v-select>
                    </v-sheet>

                    <!-- 키워드 -->
                    <v-text-field
                        placeholder="검색어를 입력해주세요"
                        outlined
                        dense
                        hide-details
                        v-model="keyword"
                        @keyup.enter="search()"
                    ></v-text-field>

                    <!-- 제출 버튼 -->
                    <v-btn
                        height="40"
                        class="px-6 ml-3"
                        color="primary"
                        dark
                        depressed
                        @click="search()"
                    >
                        <v-icon small left>mdi-magnify</v-icon>
                        검색하기
                    </v-btn>
                </v-sheet>
            </v-sheet>

            <!-- 목록 -->
            <v-radio-group v-model="selected" hide-details>
                <v-data-table
                    hide-default-header
                    :items="list"
                    :footer-props="{
                        'items-per-page-options': [8, 20, 100, -1],
                        'itemsPerPageText': '페이지당 사용자 수'
                    }"
                    no-data-text=""
                >
                    <template v-slot:header>
                        <tr>
                            <th style="width:52px;"></th>
                            <th style="width:58px;">이미지</th>
                            <th style="width:160px">아이디</th>
                            <th style="width:180px">닉네임</th>
                            <th style="width:180px">이메일</th>
                            <th style="width:120px">카카오톡 마케팅 동의</th>
                            <th style="width:120px">SMS 마케팅 동의</th>
                            <th style="width:120px">앱 PUSH 마케팅 동의</th>
                            <th style="width:220px">가입일자</th>
                        </tr>
                    </template>
                    <template v-slot:item="{ item }">
                        <tr>
                            <td>
                                <v-radio
                                    class="ml-1"
                                    hide-details
                                    dense
                                    :value="item"
                                    @change="selectedItem = item"
                                ></v-radio>
                            </td>
                            <td>
                                <v-avatar color="rgb(219, 219, 219)" size="52">
                                    <v-img :src="'/upload/user/profile/'+item.profile_image"></v-img>
                                </v-avatar>
                            </td>
                            <td><font class="d-block text-truncate" style="width:160px;">{{item.user_id}}</font></td>
                            <td><font class="d-block text-truncate" style="width:180px;">{{item.nickname}}</font></td>
                            <td><font class="d-block text-truncate" style="width:180px;">{{item.email}}</font></td>
                            <td>
                                <font
                                    class="d-block text-truncate font-weight-medium"
                                    style="width:120px;"
                                    :class="item.marketing_kakao? 'green--text':'primary--text'"
                                >
                                {{item.marketing_kakao? '동의':'거부'}}
                                </font>
                            </td>
                            <td>
                                <font
                                    class="d-block text-truncate font-weight-medium"
                                    style="width:120px;"
                                    :class="item.marketing_sms? 'green--text':'primary--text'"
                                >
                                {{item.marketing_sms? '동의':'거부'}}
                                </font>
                            </td>
                            <td>
                                <font
                                    class="d-block text-truncate font-weight-medium"
                                    style="width:120px;"
                                    :class="item.marketing_app? 'green--text':'primary--text'"
                                >
                                {{item.marketing_app? '동의':'거부'}}
                                </font>
                            </td>
                            <td><font class="d-block text-truncate" style="width:220px;">{{new Date(item.created).toLocaleString()}}</font></td>
                        </tr>
                    </template>
                </v-data-table>
            </v-radio-group>

            <!-- 버튼 -->
            <v-card class="mt-2 pa-2 d-flex justify-end" outlined>
                <v-btn
                    class="px-6 ml-3"
                    color="red"
                    large
                    dark
                    depressed
                    @click="deleteItem()"
                >
                    <v-icon small left>mdi-database-remove-outline</v-icon>
                    삭제하기
                </v-btn>
            </v-card>
        </v-sheet>
    </div>
</template>
<script>
export default {
    data: () => ({
        list: [],

        select_list: {
            search_type: [
                "유저 아이디",
                "닉네임",
                "이메일"
            ],
        },

        selected: false,
        selectedItem: {},

        search_type: "유저 아이디",
        keyword: ""
    }),

    mounted(){
        // 검색
        this.search()
    },

    methods: {
        // 검색
        search(){
            this.$http.post('/api/user/select/search', {
                params: {
                    search_type: this.search_type,
                    keyword: this.keyword
                }
            }).then((res) => {
                this.list = res.data
            })
        },

        // 삭제하기
        deleteItem(){
            if(!Object.keys(this.selectedItem).length){
                alert("선택한 사용자가 없습니다")
            }else{
                if(confirm("정말 해당 사용자를 삭제하겠습니까?")){
                    this.$http.post('/api/user/delete', {
                        params: {
                            user_id: this.selectedItem.user_id
                        }
                    }).then((res) => {
                        if(res.data.affectedRows){
                            this.search()
                        }
                    })
                }
            }
        }
    }
}
</script>
<template>
    <v-sheet
        class="text-center py-16"
    >
        <v-progress-circular
            indeterminate
            color="primary"
            size="48"
        ></v-progress-circular>
        <p
            class="mt-4 text-caption"
        >
            Google 로그인중
        </p>
    </v-sheet>
</template>
<script>
import { mapMutations } from 'vuex'

export default {
    mounted(){
        const formData = new URLSearchParams()
        formData.append('code', this.$route.query.code)
        formData.append('client_id', '431163131298-pvo7r3ct3jc1bvimpb4932t8930q28vn.apps.googleusercontent.com')
        formData.append('client_secret', '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')
        formData.append('redirect_uri', 'http://localhost:8081/auth/sns/google')
        formData.append('grant_type', 'authorization_code')

        this.$http.post("https://oauth2.googleapis.com/token", formData, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then((res) => {
            console.log(res)
            this.$http.get("https://www.googleapis.com/oauth2/v3/userinfo", {
                headers: {
                    Authorization: `Bearer ${res.data.access_token}`
                }
            }).then((response) => {
                console.log(response.data); // 사용자 정보 출력

                if(response){
                    this.$http.post('/api/user/insert', {
                        params: {
                            user_id: response.data.email.split('@')[0],
                            password: response.data.sub,
                            nickname: response.data.name,
                            email: response.data.email,
                            profile_image: response.data.picture,
                            status: '인증완료'
                        }
                    }).then((res) => {
                        let user_info = {
                            user_id: response.data.email.split('@')[0],
                            nickname: response.data.name,
                            email: response.data.email,
                            profile_image: response.data.picture,
                            type: "사용자",
                            status: "인증완료",
                            created: new Date()
                        }

                        this.login(user_info)
                    })
                }
            })
        }).catch((error) => {
            console.error(error)
        })
    },

    methods: {
        ...mapMutations(['login']),
    }
}
</script>
<template>
    <!-- # 테스트 -->
    <v-sheet
        style="position:absolute; right:10px; top:160px; border:2px solid #0369A1;"
        width="476"
        class="rounded-15 pa-4"
    >
        <p
            class="text-subtitle-1 font-weight-bold mb-2"
        >
            계산식
        </p>
        <div
            v-for="(item, index) in list" :key="index"
            class="mb-0 text-body-2"
        >
            <pre>{{JSON.stringify(item, null, 4)}}</pre>
        </div>
        <v-divider class="mt-4 mb-3"></v-divider>
        <p
            class="text-subtitle-1 font-weight-bold mb-2"
        >
            입력 키
        </p>
        <div
            class="d-flex flex-wrap"
            style="width:100%;"
        >
            <v-sheet
                v-for="(key, index) in $store.state.keyList" :key="index"
                class="py-1 mr-1 mb-1 text-center rounded-5"
                width="36"
                style="border:1px solid #81b4d0;"
            >
                {{key}}
            </v-sheet>
        </div>
    </v-sheet>
</template>
<script>
export default {
    props: [
        "list"
    ]
}
</script>
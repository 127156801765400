<template>
    <div>
        <!-- PC -->
        <div
            v-if="!$vuetify.breakpoint.mobile"
            style="height:100vh; margin:0 auto; box-shadow: -6px 0 4px -4px #eee, 6px 0 4px -4px #eee;"
            :style="$store.state.screen_mode == 'expanded'? 'width:880px;':'width:440px;'"
        >
            <AppHeader
                v-if="!$store.state.twin"
                style="height:48px;"
            />
            <div
                class="d-flex"
                style="flex: 1; overflow: hidden; max-height:calc(100% - 48px);"
            >
                <div
                    style="width:440px; border-right:1px solid #eee; overflow:hidden;"
                >
                    <HomeHeader
                        v-if="!$store.state.twin"
                    />
                    <router-view
                        :key="$route.fullPath"
                        style="height:100%;"
                    ></router-view>
                </div>
                <div
                    v-if="$store.state.screen_mode == 'expanded'"
                    style="width:440px; height:900px; overflow:hidden;"
                >
                    <ListHeader />
                    <ViewsList />
                </div>
            </div>
        </div>

        <!-- Mobile -->
        <div
            v-if="$vuetify.breakpoint.mobile"
            style="height:300px;"
        >
            <AppHeader />

            <HomeHeader
                v-if="$route.path == '/' || $route.path == '/mypage'"
            />
            <ListHeader
                v-if="$route.path == '/list'"
            />
            <router-view
                :key="$route.fullPath"
            ></router-view>
        </div>
    </div>
</template>

<script>
import AppHeader from './header/AppHeader'
import HomeHeader from './header/HomeHeader'
import ListHeader from './header/ListHeader'
import ViewsList from '@/views/List'
import List from '@/components/List'

export default {
    components: {
        AppHeader,
        HomeHeader,
        ListHeader,
        ViewsList,
        List
    }
}
</script>
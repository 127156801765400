<template>
    <div
        style="height:100%;"
    >
        <!-- # 같이보기 -->
        <div
            v-if="$store.state.main_tab == '계산' && $store.state.twin"
            style="max-height:280px; overflow-y:auto"
            class="rotate primary"
        >
            <!-- # 한줄 보기 -->
            <v-sheet
                v-if="$store.state.line == 'single'"
                class="mx-auto pa-4 mt-2 pb-10 rounded-10"
                min-height="180"
                color="transparent"
            >
                <!-- # 계산식 -->
                <div
                    class="pa-1 d-flex justify-end flex-wrap"
                    style="text-align:end; min-height:60px;"
                >
                    <!-- # 계산식 배열 -->
                    <div
                        v-for="(item, index) in list" :key="index"
                        class="font-weight-medium d-flex justify-end"
                    >
                        <!-- # 연산자 -->
                        <div
                            class="mt-3px mx-1"
                        >
                            <!-- # 연산자 기본모드 -->
                            <div
                                class="text-h6 font-weight-bold operator--text white--text"
                            >
                                {{$pretty_operator(item.operator)}}
                            </div>
                        </div>

                        <!-- # 숫자 기본모드 -->
                        <div
                            style="z-index:999;"
                        >
                            <!-- # 메인 계산식 -->
                            <p
                                class="mb-0 text-h5 white--text"
                            >
                                {{$formula_main(item.number, item.scale, $store.state.sub_tab)}}
                            </p>

                            <!-- # 서브 계산식 -->
                            <p
                                class="mb-0 white--text"
                                style="margin-top:-2px; font-size:16px; font-weight:400;"
                            >
                                {{$formula_sub(item.number, item.scale, $store.state.sub_tab, $store.state.input_tab)}}
                            </p>
                        </div>
                    </div>
                </div>

                <!-- # 결과값 -->
                <div
                    class="text-end font-weight-bold pa-1 mt-4 text-white"
                    style="color:white; height:32px;"
                >
                    <!-- # 결과값 메인줄 -->
                    <div v-if="!list.some(item => item.edit)" class="font-weight-bold text-h5 white--text">
                        {{$result_main(list, $store.state.sub_tab)}}
                    </div>

                    <!-- # 결과값 서브줄 -->
                    <div v-if="!list.some(item => item.edit)" class="text-h6 white--text">
                        {{$result_sub(list, $store.state.sub_tab, $store.state.input_tab)}}
                    </div>
                </div>
            </v-sheet>
        </div>

        <!-- # 표시 화면 -->
        <div
            class="pa-2 pt-0"
            style="max-height:calc(100% - 160px);"
        >
            <HomeHeader
                v-if="$store.state.twin"
            />

            <!-- # 메인탭 : 계산 -->
            <div
                v-if="$store.state.main_tab == '계산'"
                style="max-height:280px; overflow-y:auto"
                @click="$edit_end(list); $edit_end2(list);"
            >
                <!-- # 한줄 보기 -->
                <v-sheet
                    v-if="$store.state.line == 'single'"
                    class="mx-auto pa-4 mt-2 pb-10 rounded-10"
                    min-height="180"
                >
                    <!-- # 계산식 -->
                    <div
                        class="pa-1 d-flex justify-end flex-wrap"
                        style="text-align:end; min-height:60px;"
                    >
                        <!-- # 계산식 배열 -->
                        <div
                            v-for="(item, index) in list" :key="index"
                            class="font-weight-medium d-flex justify-end"
                        >
                            <!-- # 연산자 -->
                            <div
                                class="mt-3px mx-1"
                            >
                                <!-- # 연산자 기본모드 -->
                                <div
                                    v-show="!item.edit2"
                                    @click.stop="$edit_start2(list, item, index); focusStart('operator'+index)"
                                    class="text-h6 font-weight-bold operator--text"
                                >
                                    {{$pretty_operator(item.operator)}}
                                </div>

                                <!-- # 연산자 수정모드 -->
                                <div
                                    v-show="item.edit2"
                                    @click.stop=""
                                    style="position:relative;"
                                >
                                    <v-text-field
                                        v-model="item.edit_operator"
                                        class="edit_mode2"
                                        style="min-width:20px;"
                                        :style="{ width: $dynamicWidth2(item) }"
                                        solo
                                        flat
                                        dense
                                        hide-details
                                        inputmode="none"
                                        :ref="'operator'+index"
                                        @focus="setActiveInput('operator'+index, 'operator', index)"
                                        @input="handleInput(item, index)"
                                    ></v-text-field>
                                </div>
                            </div>

                            <!-- # 숫자 기본모드 -->
                            <div
                                v-show="!item.edit"
                                @click.stop="$edit_end(list); $edit_start(list, item, index); focusStart('number'+index)"
                                style="z-index:999;"
                            >
                                <!-- # 메인 계산식 -->
                                <p
                                    class="mb-0 text-h5 alter_black--text"
                                >
                                    {{$formula_main(item.number, item.scale, $store.state.sub_tab)}}
                                </p>

                                <!-- # 서브 계산식 -->
                                <p
                                    class="mb-0 alter_grey--text"
                                    style="margin-top:-2px; font-size:16px; font-weight:400;"
                                >
                                    {{$formula_sub(item.number, item.scale, $store.state.sub_tab, $store.state.input_tab)}}
                                </p>
                            </div>

                            <!-- # 숫자 수정모드 -->
                            <div
                                v-show="item.edit"
                                @click.stop=""
                                style="position:relative;"
                            >
                                <v-text-field
                                    v-model="item.edit_number"
                                    class="edit_mode"
                                    :style="{ width: $dynamicWidth(item) }"
                                    solo
                                    flat
                                    dense
                                    hide-details
                                    inputmode="none"
                                    :ref="'number'+index"
                                    @focus="setActiveInput('number'+index, 'number', index)"
                                    @input="$checkDisableKey(list)"
                                ></v-text-field>
                                <p
                                    style="position:absolute; width:48px; right:0px; height:26px; margin-top:-11px;"
                                    class="mb-0 text-caption alter_grey--text font-weight-medium text-end"
                                >
                                    수정모드
                                </p>
                            </div>
                        </div>

                        <!-- # 없을때 -->
                        <div
                            v-show="list.length == 0"
                        >
                            <div
                                ref="toggleElement"
                                style="width:1px; height:29px; background:#374151; margin-top:1px;"
                            ></div>
                        </div>
                    </div>

                    <!-- # 결과값 -->
                    <div
                        class="text-end font-weight-bold pa-1 mt-4"
                        style="color:#0369a1; height:32px;"
                    >
                        <!-- # 결과값 메인줄 -->
                        <div v-if="!list.some(item => item.edit)" class="font-weight-bold text-h5">
                            {{$result_main(list, $store.state.sub_tab)}}
                        </div>

                        <!-- # 결과값 서브줄 -->
                        <div v-if="!list.some(item => item.edit)" class="text-h6">
                            {{$result_sub(list, $store.state.sub_tab, $store.state.input_tab)}}
                        </div>
                    </div>
                </v-sheet>
            </div>

            <!-- # 메인탭 : 환전 -->
            <div
                v-if="$store.state.main_tab == '환전'"
                style="padding-bottom:160px; overflow-y:auto; position:relative;"
                :style="$store.state.keypad_mode == 'expanded'? 'max-height:440px;': 'max-height:calc(100% - 160px);'"
            >
                <div v-show="false">
                    {{$result_main(list, $store.state.sub_tab)}}
                </div>

                <div>
                    <draggable
                        v-model="exchange_list"
                        ghost-class="ghost"
                        @start="onStart"
                        @end="onEnd"
                        :delay="200"
                    >
                        <!-- # 환전 통화 목록 -->
                        <v-sheet
                            v-for="(item, index) in exchange_list" :key="index"
                            class="rounded-12 d-flex align-end pt-3 pb-3 mt-3 px-4 mx-1"
                            style="box-shadow:0px 0px 4px 2px #e4e4e4; position:relative;"
                            :class="item.is_expanded? 'gradient-background':''"
                            :style="item.is_expanded? 'min-height:124px;':'height:78px;'"
                        >
                            <!-- # 환전 통화 선택 -->
                            <div
                                class="ml-3"
                                @click.stop="dialog.exchange_change = true; selected_index = index"
                            >
                                <div
                                    class="d-flex align-end"
                                    style="height:30px;"
                                >
                                    <!-- 이미지 -->
                                    <v-sheet
                                        width="24"
                                        height="24"
                                    >
                                        <!-- 통화 -->
                                        <v-img
                                            v-if="item.type == 'currency'"
                                            class="rounded-circle mx-auto"
                                            style="box-shadow:0px 0px 4px #ccc;"
                                            :src="require('@/assets/flags/'+item.flag)"
                                        ></v-img>

                                        <!-- 암호화폐 -->
                                        <v-img
                                            v-if="item.type == 'coin'"
                                            class="rounded-circle mx-auto"
                                            style="box-shadow:0px 0px 4px #ccc;"
                                            :src="require('@/assets/coins/'+item.flag)"
                                        ></v-img>
                                    </v-sheet>

                                    <div
                                        class="ml-2"
                                        style="height:30px;"
                                    >
                                        <p
                                            style="font-family: 'Roboto', sans-serif !important; font-size:14px; color:#858d99;"
                                            class="text-center mb-0 mt-5px"
                                        >
                                            {{item.code}}
                                        </p>
                                        <v-icon
                                            style="margin-top:-9px;"
                                            size="20"
                                            color="#858d99"
                                            class="d-flex mx-auto"
                                        >
                                            mdi-chevron-down
                                        </v-icon>
                                    </div>
                                </div>

                                <!-- 한글명 -->
                                <p
                                    class="alter_black--text mb-0 text-caption ml-1px"
                                    style="height:18px;"
                                >
                                    {{item.name}}
                                </p>
                            </div>

                            <v-spacer></v-spacer>

                            <!-- # 펼치기 -->
                            <v-btn
                                v-if="!item.is_expanded"
                                icon
                                x-small
                                depressed
                                style="position:absolute; top:4px; left:4px; z-index:3;"
                                @click.stop="toggleExpand(item)"
                            >
                                <v-img
                                    width="20"
                                    height="20"
                                    contain
                                    src="@/assets/close_expand.svg"
                                ></v-img>
                            </v-btn>

                            <!-- # 접기 -->
                            <v-btn
                                v-if="item.is_expanded"
                                icon
                                x-small
                                depressed
                                style="position:absolute; top:4px; left:4px; z-index:3;"
                                @click.stop="item.is_expanded = false"
                            >
                                <v-img
                                    width="20"
                                    height="20"
                                    contain
                                    src="@/assets/open_expand.svg"
                                ></v-img>
                            </v-btn>

                            <!-- # 삭제 -->
                            <v-btn
                                v-if="index != 0"
                                icon
                                x-small
                                depressed
                                style="position:absolute; bottom:4px; left:4px; z-index:3;"
                                @click.stop="exchange_list.splice(index, 1)"
                            >
                                <v-img
                                    width="20"
                                    height="20"
                                    contain
                                    src="@/assets/dump.svg"
                                ></v-img>
                            </v-btn>

                            <!-- # 고정핀  -->
                            <v-btn
                                v-if="index == 0"
                                icon
                                x-small
                                depressed
                                disabled
                                style="position:absolute; bottom:4px; left:4px; z-index:3;"
                                @click.stop=""
                            >
                                <v-img
                                    width="20"
                                    height="20"
                                    contain
                                    src="@/assets/fix.svg"
                                ></v-img>
                            </v-btn>

                            <!-- # 환전 통화 -->
                            <div
                                class="align-self-end ml-1"
                                style="width:100%;"
                                @click.stop="$edit_end(list); $edit_end2(list);"
                            >
                                <!-- # 계산식 -->
                                <div
                                    v-if="item.is_expanded"
                                    class="pa-1 d-flex justify-end flex-wrap"
                                    style="text-align:end; min-height:60px;"
                                >
                                    <!-- # 계산식 배열 -->
                                    <div
                                        v-for="(item, index) in list" :key="index"
                                        class="font-weight-medium d-flex justify-end"
                                    >
                                        <!-- # 연산자 -->
                                        <div
                                            class="mt-3px mx-1"
                                        >
                                            <!-- # 연산자 기본모드 -->
                                            <div
                                                v-show="!item.edit2"
                                                @click.stop="$edit_start2(list, item, index); focusStart('operator'+index)"
                                                class="text-h6 font-weight-bold operator--text"
                                            >
                                                {{$pretty_operator(item.operator)}}
                                            </div>

                                            <!-- # 연산자 수정모드 -->
                                            <div
                                                v-show="item.edit2"
                                                @click.stop=""
                                                style="position:relative;"
                                            >
                                                <v-text-field
                                                    v-model="item.edit_operator"
                                                    class="edit_mode2"
                                                    style="min-width:20px;"
                                                    :style="{ width: $dynamicWidth2(item) }"
                                                    solo
                                                    flat
                                                    dense
                                                    hide-details
                                                    inputmode="none"
                                                    :ref="'operator'+index"
                                                    @focus="setActiveInput('operator'+index, 'operator', index)"
                                                    @input="handleInput(item, index)"
                                                ></v-text-field>
                                            </div>
                                        </div>

                                        <!-- # 숫자 기본모드 -->
                                        <div
                                            v-show="!item.edit"
                                            @click.stop="$edit_end(list); $edit_start(list, item, index); focusStart('number'+index)"
                                            style="z-index:999;"
                                        >
                                            <!-- # 메인 계산식 -->
                                            <p
                                                class="mb-0 text-h5 alter_black--text"
                                            >
                                                {{$formula_main(item.number, item.scale, $store.state.sub_tab)}}
                                            </p>

                                            <!-- # 서브 계산식 -->
                                            <p
                                                class="mb-0 alter_grey--text"
                                                style="margin-top:-2px; font-size:16px; font-weight:400;"
                                            >
                                                {{$formula_sub(item.number, item.scale, $store.state.sub_tab, $store.state.input_tab)}}
                                            </p>
                                        </div>

                                        <!-- # 숫자 수정모드 -->
                                        <div
                                            v-show="item.edit"
                                            @click.stop=""
                                            style="position:relative; margin-bottom:11px;"
                                        >
                                            <v-text-field
                                                v-model="item.edit_number"
                                                class="edit_mode"
                                                :style="{ width: $dynamicWidth(item) }"
                                                solo
                                                flat
                                                dense
                                                hide-details
                                                inputmode="none"
                                                :ref="'number'+index"
                                                @focus="setActiveInput('number'+index, 'number', index)"
                                                @input="$checkDisableKey(list)"
                                            ></v-text-field>
                                            <p
                                                style="position:absolute; width:48px; right:0px; height:26px; margin-top:-11px;"
                                                class="mb-0 text-caption alter_grey--text font-weight-medium"
                                            >
                                                수정모드
                                            </p>
                                        </div>
                                    </div>

                                    <!-- # 없을때 -->
                                    <div
                                        v-show="list.length == 0"
                                    >
                                        <div
                                            ref="toggleElement"
                                            style="width:1px; height:29px; background:#374151; margin-top:1px;"
                                        ></div>
                                    </div>
                                </div>
                                <div
                                    v-if="index == 0"
                                >
                                    <p
                                        class="pr-1 text-right text-h6 mb-0 primary--text"
                                        :class="list.length == 0? 'grey--text':''"
                                        style="height:30px;"
                                    >
                                        <span>{{$result_currency_second_main(list, $store.state.sub_tab, item.value, item.value)}}</span>
                                    </p>
                                    <p
                                        class="pr-1 text-right primary--text text--lighten-1 mb-0"
                                        style="height:18px; font-size:16px; line-height:18px;"
                                    >
                                        <!-- v-if="list.length != 0 && $store.state.sub_tab == '000'" -->
                                        {{$result_currency_second_sub(list, item.show_tab, item.value, item.value)}}
                                    </p>
                                </div>
                                <div
                                    v-else
                                >
                                    <p
                                        class="pr-1 text-right text-h6 mb-0 primary--text"
                                        :class="list.length == 0? 'grey--text':''"
                                        style="height:30px;"
                                    >
                                        <span>{{$result_currency_second_main(list, $store.state.sub_tab, exchange_list[0].value, item.value)}}</span>
                                    </p>

                                    <p
                                        v-if="list.length != 0"
                                        class="pr-1 text-right primary--text text--lighten-1 mb-0"
                                        style="height:18px; font-size:16px; line-height:18px;"
                                    >
                                        {{$result_currency_second_sub(list, item.show_tab, exchange_list[0].value, item.value)}}
                                    </p>
                                    <p
                                        v-if="list.length == 0"
                                        class="pr-1 text-right mb-0"
                                        style="height:18px; font-size:16px; line-height:18px; color:#545d6e; font-weight:500;"
                                    >
                                        {{item.icon}}{{exchange_list[0].value}}<span class="mx-2">=</span>{{exchange_list[0].icon}}{{item.value}}
                                    </p>
                                </div>
                            </div>
                            <div
                                class="align-self-end"
                                style="width:26px !important; height:48px;"
                            >
                                <div
                                    class="text-h6 mb-0 primary--text text-center text-truncate"
                                    style="height:30px;"
                                >
                                    {{item.icon}}
                                </div>

                                <!-- # 입력탭 (input_tab) -->
                                <div
                                    v-if="(index == 0 && $result_currency_second_sub(list, item.show_tab, item.value, item.value)) || (index != 0 && $result_currency_second_sub(list, item.show_tab, exchange_list[0].value, item.value))"
                                    class="d-flex align-end justify-center"
                                    style="height:18px; cursor:pointer;"
                                    @click.stop="item.show_tab = item.show_tab == '만억조' ? 'KMB' : '만억조'"
                                >
                                    <!-- 모드 -->
                                    <div
                                        v-if="item.show_tab == '만억조'"
                                        class="d-flex rounded-15 pa-0"
                                        style="position:relative; width:26px !important; padding-left:2px !important;"
                                    >
                                        <v-btn
                                            width="14"
                                            height="14"
                                            x-small
                                            depressed
                                            v-ripple="false"
                                            style="padding:0; padding-bottom:1px; margin:1px; letter-spacing:1px !important; min-width:0px; z-index: 2;"
                                            class="rounded-5 font-weight-bold"
                                            :style="item.show_tab == '만억조'? 'color:white; background:#858d99;':'background:white; color:#667080;'"
                                        >   
                                            <span v-if="item.show_tab == '만억조'" style="font-size:9px; font-weight:400; margin-left:1px;">만</span>
                                        </v-btn>
                                        <div
                                            style="
                                                z-index: 1;
                                                background: #c3c6cb;
                                                height: 4px;
                                                width: 12px;
                                                border-radius: 10px;
                                                position: absolute;
                                                left: 10px;
                                                top: 6px;
                                            "
                                        ></div>
                                    </div>

                                    <!-- 모드 -->
                                    <div
                                        v-if="item.show_tab == 'KMB'"
                                        class="d-flex rounded-15 pa-0 justify-end"
                                        style="position:relative; width:26px !important; padding-right:2px !important;"
                                    >
                                        <v-btn
                                            width="14"
                                            height="14"
                                            x-small
                                            depressed
                                            v-ripple="false"
                                            style="padding:0; padding-bottom:1px; margin:1px; letter-spacing:1px !important; min-width:0px; z-index: 2;"
                                            class="rounded-5 font-weight-bold"
                                            :style="item.show_tab == 'KMB'? 'color:white; background:#858d99;':'background:white; color:#667080;'"
                                        >   
                                            <span v-if="item.show_tab == 'KMB'" style="font-size:9px; font-weight:400; margin-left:1px;">K</span>
                                        </v-btn>
                                        <div
                                            style="
                                                z-index: 1;
                                                background: #c3c6cb;
                                                height: 4px;
                                                width: 12px;
                                                border-radius: 10px;
                                                position: absolute;
                                                left: 4px;
                                                top: 6px;
                                            "
                                        ></div>
                                    </div>
                                </div>
                            </div>

                            <!-- # 스왑 -->
                            <v-btn
                                v-if="index == 0 && exchange_list.length == 2"
                                class="pa-1"
                                fab
                                x-small
                                depressed
                                color="white"
                                style="box-shadow:0px 2px 6px 4px #eee; position:absolute; left:50%; transform:translateX(-50%); bottom:-20px; z-index:3;"
                                @click="swapCurrencies()"
                            >
                                <v-img
                                    width="24"
                                    height="24"
                                    contain
                                    src="@/assets/switch.svg"
                                ></v-img>
                            </v-btn>
                        </v-sheet>
                    </draggable>

                    <v-sheet
                        class="rounded-12 d-flex align-center justify-center py-1 mt-3 px-4 mx-1"
                        style="box-shadow:0px 0px 4px 2px #e4e4e4; cursor:pointer;"
                        @click="dialog.exchange_add = true"
                    >
                        <v-icon color="primary">mdi-plus</v-icon>
                    </v-sheet>
                </div>
            </div>
        </div>

        <!-- # 환전 변경 Dialog -->
        <v-dialog
            v-model="dialog.exchange_change"
            width="auto"
            content-class="rounded-10"
            style="z-index:9999;"
        >
            <v-sheet
                class="rounded-10"
            >
                <ExchangeListAdd
                    :original_currency="exchange_list[0].value"
                    @selected="selected_change_handler"
                />
            </v-sheet>
        </v-dialog>

        <!-- # 환전 추가 Dialog -->
        <v-dialog
            v-model="dialog.exchange_add"
            width="auto"
            content-class="rounded-10"
            style="z-index:9999;"
        >
            <v-sheet
                class="rounded-10"
            >
                <ExchangeListAdd
                    :original_currency="exchange_list[0].value"
                    @selected="selected_handler3"
                />
            </v-sheet>
        </v-dialog>

        <!-- # 키패드 -->
        <KeyPad
            style="position:absolute; bottom:0px;"
            :style="$vuetify.breakpoint.mobile? 'width:100vw':'width:440px'"
            class="mt-2"
            @keyPress="keyPress_Handler"
        />
    </div>
</template>
<script>
import ExchangeListAdd from '@/components/ExchangeListAdd'
import KeyPad from '@/components/KeyPad'
import TestPad from '@/components/TestPad'
import HomeHeader from '@/layouts/header/HomeHeader'
import draggable from 'vuedraggable'

export default {
    components: {
        ExchangeListAdd,
        KeyPad,
        TestPad,
        HomeHeader,
        draggable
    },

    data: () => ({
        list: [],
        scales: {
            '만': 10000,
            '억': 100000000,
            '조': 1000000000000,
            'K': 1000,
            'M': 1000000,
            'B': 1000000000,
            'T': 1000000000000
        },

        activeInput: null,
        activeType: "",
        activeIndex: 0,
        drag: false,

        currencies: [
            { type: "currency", name: "한국", flag: "kr.svg", show_tab: "만억조", code: "KOR", value: 1, icon: "₩" },
            { type: "currency", flag: "us.svg", chosung: "ㅁㄱ", name: "미국", code: "USD", value: 1339.60, rate: 0.04, show_tab: "만억조", is_expanded: false, is_bookmarked: false, icon: "$" }, 
            { type: "currency", flag: "eu.svg", chosung: "ㅇㄹ", name: "유로", code: "EUR", value: 1480.46, rate: 0.05, show_tab: "만억조", is_expanded: false, is_bookmarked: false, icon: "€" }, 
            { type: "currency", flag: "jp.svg", chosung: "ㅇㅂ", name: "일본", code: "JPY", value: 916.56, rate: 0.03, show_tab: "만억조", is_expanded: false, is_bookmarked: false, icon: "¥" }, 
            { type: "currency", flag: "cn.svg", chosung: "ㅈㄱ", name: "중국", code: "CNY", value: 187.80, rate: 0.38, show_tab: "만억조", is_expanded: false, is_bookmarked: false, icon: "¥" },
            { type: "currency", flag: "gb.svg", chosung: "ㅇㄱ", name: "영국", code: "GBP", value: 1745.56, rate: 0.61, show_tab: "만억조", is_expanded: false, is_bookmarked: false, icon: "£" }, 
            { type: "currency", flag: "au.svg", chosung: "ㅎㅈ", name: "호주", code: "AUD", value: 902.49, rate: 0.50, show_tab: "만억조", is_expanded: false, is_bookmarked: false, icon: "A$" },

            { type: "currency", flag: "ca.svg", chosung: "ㅋㄴㄷ", name: "캐나다", code: "CAD", value: 984.53, rate: 0.70, show_tab: "만억조", is_expanded: false, is_bookmarked: false, icon: "C$" },
            { type: "currency", flag: "nz.svg", chosung: "ㄴㅈㄹㄷ", name: "뉴질랜드", code: "NZD", value: 822.18, rate: 0.31, show_tab: "만억조", is_expanded: false, is_bookmarked: false, icon: "NZ$" },
            { type: "currency", flag: "th.svg", chosung: "ㅌㄱ", name: "태국", code: "THB", value: 39.03, rate: 0.23, show_tab: "만억조", is_expanded: false, is_bookmarked: false, icon: "฿" }, 
            { type: "currency", flag: "vn.svg", chosung: "ㅂㅌㄴ", name: "베트남", code: "VND", value: 5.37, rate: 0.56, show_tab: "만억조", is_expanded: false, is_bookmarked: false, icon: "V" }, //!
            { type: "currency", flag: "hk.svg", chosung: "ㅎㅋ", name: "홍콩", code: "HKD", value: 171.91, rate: 0.56, show_tab: "만억조", is_expanded: false, is_bookmarked: false, icon: "HK$" },
            { type: "currency", flag: "tw.svg", chosung: "ㄷㅁ", name: "대만", code: "TWD", value: 41.96, rate: 0.55, show_tab: "만억조", is_expanded: false, is_bookmarked: false, icon: "D" }, //!

            { type: "currency", flag: "ch.svg", chosung: "ㅅㅇㅅ", name: "스위스", code: "CHF", value: 1566.93, rate: 0.47, show_tab: "만억조", is_expanded: false, is_bookmarked: false, icon: "CHF" },
            { type: "currency", flag: "se.svg", chosung: "ㅅㅇㄷ", name: "스웨덴", code: "SEK", value: 130.87, rate: 0.31, show_tab: "만억조", is_expanded: false, is_bookmarked: false, icon: "SEK" },
            { type: "currency", flag: "in.svg", chosung: "ㅇㄷ", name: "인도", code: "INR", value: 15.97, rate: 0.44, show_tab: "만억조", is_expanded: false, is_bookmarked: false, icon: "INR" },
            { type: "currency", flag: "ru.svg", chosung: "ㄹㅅㅇ", name: "러시아", code: "RUB", value: 14.59, rate: -0.14, show_tab: "만억조", is_expanded: false, is_bookmarked: false, icon: "RUB" },
            { type: "currency", flag: "br.svg", chosung: "ㅂㄹㅈ", name: "브라질", code: "BRL", value: 244.51, rate: 0.62, show_tab: "만억조", is_expanded: false, is_bookmarked: false, icon: "BRL" },
            { type: "currency", flag: "mx.svg", chosung: "ㅁㅅㅋ", name: "멕시코", code: "MXN", value: 70.72, rate: 0.96, show_tab: "만억조", is_expanded: false, is_bookmarked: false, icon: "MXN" },

            { type: "currency", flag: "tr.svg", chosung: "ㅌㅋ", name: "터키", code: "TRY", value: 39.50, rate: 0.59, show_tab: "만억조", is_expanded: false, is_bookmarked: false, icon: "TRY" },
            { type: "currency", flag: "no.svg", chosung: "ㄴㄹㅇㅇ", name: "노르웨이", code: "NOK", value: 127.40, rate: 0.47, show_tab: "만억조", is_expanded: false, is_bookmarked: false, icon: "NOK" },
            { type: "currency", flag: "il.svg", chosung: "ㅇㅅㄹㅇ", name: "이스라엘", code: "ILS", value: 359.46, rate: -0.33, show_tab: "만억조", is_expanded: false, is_bookmarked: false, icon: "이스라엘" },
            { type: "currency", flag: "my.svg", chosung: "ㅁㄹㅇㅅㅇ", name: "말레이시아", code: "MYR", value: 305.82, rate: 0.54, show_tab: "만억조", is_expanded: false, is_bookmarked: false, icon: "말레이시아" },
            { type: "currency", flag: "hu.svg", chosung: "ㅎㄱㄹ", name: "헝가리", code: "HUF", value: 3.79, rate: 0.53, show_tab: "만억조", is_expanded: false, is_bookmarked: false, icon: "헝가리" },
            { type: "currency", flag: "pl.svg", chosung: "ㅍㄹㄷ", name: "폴란드", code: "PLN", value: 347.47, rate: 0.28, show_tab: "만억조", is_expanded: false, is_bookmarked: false, icon: "폴란드" },

            // 암호화폐
            { type: "coin", flag: "비트코인.png", chosung: "ㅂㅌㅋㅇ", name: "비트코인", code: "BTC", value: 81100000, rate: 0.43, show_tab: "만억조", is_expanded: false, is_bookmarked: false, icon: "BTC" },
            { type: "coin", flag: "리플.png", chosung: "ㄹㅍ", name: "리플", code: "XRP", value: 816.4, rate: 0.53, show_tab: "만억조", is_expanded: false, is_bookmarked: false, icon: "XRP" },
            { type: "coin", flag: "솔라나.png", chosung: "ㅅㄹㄴ", name: "솔라나", code: "SOL", value: 193450, rate: -0.67, show_tab: "만억조", is_expanded: false, is_bookmarked: false, icon: "SOL" },
            { type: "coin", flag: "이더리움.png", chosung: "ㅇㄷㄹㅇ", name: "이더리움", code: "ETH", value: 3526000, rate: 0.11, show_tab: "만억조", is_expanded: false, is_bookmarked: false, icon: "ETH" },
            { type: "coin", flag: "엘프.png", chosung: "ㅇㅍ", name: "엘프", code: "ELF", value: 530.0, rate: -0.93, show_tab: "만억조", is_expanded: false, is_bookmarked: false, icon: "ELF" },
            { type: "coin", flag: "테더.png", chosung: "ㅌㄷ", name: "테더", code: "USDT", value: 1365, rate: -0.22, show_tab: "만억조", is_expanded: false, is_bookmarked: false, icon: "USDT" },
            { type: "coin", flag: "도지코인.png", chosung: "ㄷㅈㅋㅇ", name: "도지코인", code: "DOGE", value: 142.0, rate: 0.92, show_tab: "만억조", is_expanded: false, is_bookmarked: false, icon: "DOGE" },
            { type: "coin", flag: "에이다.png", chosung: "ㅇㅇㄷ", name: "에이다", code: "ADA", value: 480.9, rate: 2.43, show_tab: "만억조", is_expanded: false, is_bookmarked: false, icon: "ADA" },
            { type: "coin", flag: "트론.png", chosung: "ㅌㄹ", name: "트론", code: "TRX", value: 224.4, rate: 1.54, show_tab: "만억조", is_expanded: false, is_bookmarked: false, icon: "TRX" },
        ],

        dialog: {
            exchange_change: false,
            exchange_add: false
        },

        exchange_list: [
            { type: "currency", name: "한국", flag: "kr.svg", show_tab: "만억조", code: "KOR", value: 1, icon: "₩",is_expanded: false, },
            { type: "currency", flag: "us.svg", chosung: "ㅁㄱ", name: "미국", code: "USD", value: 1339.60, rate: 0.04, show_tab: "만억조", is_bookmarked: false, icon: "$",is_expanded: false, }, 
        ],

        selected_index: null
    }),

    watch: {
        list: {
            handler() {
                // 비활성 키 체크
                this.$checkDisableKey(this.list)
                this.$store.state.list = this.list
            },
            deep: true
        },
    },

    mounted() {
        // # 시작시 깜빡임
        this.toggleVisibility()

        this.list = this.$store.state.list
    },

    methods: {
        toggleExpand(item) {
            this.exchange_list.forEach(item => {
                item.is_expanded = false
            })
            item.is_expanded = true
        },

        selected_change_handler(item){
            console.log(item)
            this.exchange_list[this.selected_index] = item
            this.dialog.exchange_change = false
        },

        selected_handler3(item){
            console.log(item)
            this.exchange_list.push(item)
            this.dialog.exchange_add = false
        },

        swapCurrencies() {
            if (this.exchange_list.length >= 2) {
                this.exchange_list = this.exchange_list.map(item => ({...item})).reverse();
            }

            // 모든 항목의 is_expanded를 false로 설정
        this.exchange_list.forEach(item => item.is_expanded = false);
        },

        // # 연산자 수정 시
        handleInput(item, index) {
            this.$checkDisableKey(this.list)
            if (item.edit_operator === '') {
                this.focusStart('operator' + index)
            }
        },

        // # 깜빡임
        toggleVisibility() {
            const element = this.$refs.toggleElement
            if(this.$store.state.main_tab == '계산'){
                setInterval(() => {
                    element.style.display = element.style.display === 'none' ? 'block' : 'none'
                }, 500)
            }else{
                if(element && element.style){
                    element.style.display = 'none'
                }
            }
        },

        // # 입력란 Focus
        async focusStart(refName) {
            setTimeout(() => {
                this.$refs[refName][0].$el.querySelector('input').focus()
            }, 0)
		},

        // # 활성화중인 입력란 저장
        setActiveInput(refName, type, index) {
            this.activeInput = this.$refs[refName][0].$el.querySelector('input')
            this.activeType = type
            this.activeIndex = index
        },

        // # 커서 위치에 입력
        insertAtCursor(key) {
            const input = this.activeInput
            const type = this.activeType
            const index = this.activeIndex

            const start = input.selectionStart
            const end = input.selectionEnd
            console.log(111)

            if(type == "operator"){
                if(key == "←"){
                    this.list[index].edit_operator
                    = 
                    this.list[index].edit_operator.slice(0, start - 1)
                    +
                    this.list[index].edit_operator.slice(end)

                    // 커서 재설정
                    setTimeout(() => {
                        input.focus()
                        input.setSelectionRange(start - 1, start - 1)
                    }, 0)
                    return
                }else{
                    if(this.list[index].edit_operator == null){
                        this.list[index].edit_operator = this.$pretty_operator2(key)
                    }
                    else{
                        this.list[index].edit_operator
                        = 
                        this.list[index].edit_operator.slice(0, start)
                        +
                        this.$pretty_operator2(key)
                        +
                        this.list[index].edit_operator.slice(end)
                    }
                }
            }
            else if(type == "number"){
                console.log(222)
                if(key == "←"){
                    this.list[index].edit_number
                    = 
                    this.list[index].edit_number.slice(0, start - 1)
                    +
                    this.list[index].edit_number.slice(end)

                    console.log(333)

                    // 커서 재설정
                    setTimeout(() => {
                        input.focus()
                        input.setSelectionRange(start - 1, start - 1)
                    }, 0)
                    return
                }

                else{
                    this.list[index].edit_number
                    = 
                    this.list[index].edit_number.slice(0, start)
                    +
                    key
                    +
                    this.list[index].edit_number.slice(end)
                }
            }

            // 커서 재설정
            setTimeout(() => {
                input.focus()
                input.setSelectionRange(start + 1, start + 1)
            }, 0)
        },

        // # 키 입력
        keyPress_Handler(key) {
            // # 숫자 수정모드
            if(this.list.some(item => item.edit)){
                this.editNumberMode_KeyHandler(key)
                return
            }

            // # 연산자 수정모드
            else if(this.list.some(item => item.edit2)){
                this.editOperatorMode_KeyHandler(key)
                return
            }

            // # 기본모드
            else{
                this.defaultMode_KeyHandler(key)
            }
        },
        
        // # 숫자 수정모드
        async editNumberMode_KeyHandler(key){
            let editItem = this.list.find(item => item.edit)
            let editIndex = this.list.findIndex(item => item.edit)

            // 바로 전 아이템과 인덱스 찾기
            let beforeIndex = editIndex === 0 ? this.list.length - 1 : editIndex - 1
            let beforeItem = this.list[beforeIndex]

            let isLastItem = this.list.indexOf(editItem) === this.list.length - 1
            let isFirstItem = this.list.indexOf(editItem) === 0



            if(this.$keyType(key) == '기능키' || this.$keyType(key) == '연산자키'){
                // # 초기화
                if (key == "AC") {
                    if(this.list.length == 1){
                        this.list = []
                        this.$store.commit('update_edit_mode', false)
                    }else{
                        // 마지막 요소가 아니면 연산자도 같이 삭제
                        if(!isLastItem){
                            this.list[editIndex+1].operator = null
                            this.list.splice(editIndex, 1)
                            this.$store.commit('update_edit_mode', false)
                            // this.$keyList_Handler(key, this.list)
                            this.$edit_end(this.list)

                        }else{
                            editItem.number = null
                            editItem.scale = null
                            editItem.edit_number = null
                            this.$edit_end(this.list)
                        }
                    }
                }

                // # 마지막 지우기
                else if (key == "←") {
                    if (editItem.edit_number) {
                        this.insertAtCursor(key)
                        return
                    }else{
                        this.list.splice(editIndex, 1)
                        this.$edit_end(this.list)
                        this.$edit_start(this.list, beforeItem, beforeIndex)
                        this.focusStart('number'+beforeIndex)
                        return
                    }
                }
                else{
                    alert("수정모드에서는 이용할 수 없습니다.")
                }
            }
            else if(this.$keyType(key) == '숫자키' || this.$keyType(key) == '단위키'){
                if(this.$store.state.sub_tab == '000'){
                    if(this.$keyType(key) == '숫자키'){
                        this.insertAtCursor(key)
                        return
                    }
                    else if(this.$keyType(key) == '단위키'){
                        alert("000모드에서는 중간수정으로 숫자만 입력 가능합니다")
                    }
                }

                if(this.$store.state.sub_tab != '000'){
                    if(editItem.edit_number){
                        const lastChar = editItem.edit_number.slice(-1)

                        if (isNaN(lastChar)) {
                            editItem.edit_number += " "
                            this.insertAtCursor(key)
                        }
                        else{
                            this.insertAtCursor(key)
                        }
                    }
                }
            }

            await this.$edit_end(this.list)
        },

        // # 연산자 수정모드
        editOperatorMode_KeyHandler(key){
            let editItem = this.list.find(item => item.edit2)
            let isLastItem = this.list.indexOf(editItem) === this.list.length - 1
            let isFirstItem = this.list.indexOf(editItem) === 0
            let editIndex = this.list.findIndex(item => item.edit2)
            let beforeIndex = editIndex === 0 ? this.list.length - 1 : editIndex - 1
            let beforeItem = this.list[beforeIndex]

            if(this.$keyType(key) == '기능키'){
                // # 초기화
                if (key == "AC") {
                    if(this.list.length == 1){
                        this.list = []
                        this.$edit_end2(this.list)
                    }else{
                        editItem.operator = null
                        editItem.edit_operator = null

                        if (editItem.edit_operator) {
                            console.log("HIHIHI")
                            // this.insertAtCursor(key)
                            editItem.operator = null
                        editItem.edit_operator = null
                        } else {
                            // console.log("HI")
                            // if(editIndex == this.list.length - 1){
                            //     console.log("HI")
                            //     this.list.splice(editIndex, 1)
                            //     this.$edit_end2(this.list)
                            //     this.$edit_start(this.list, beforeItem, beforeIndex)
                            //     this.focusStart('number'+beforeIndex)
                            // }

                            this.list.splice(editIndex, 1)
                            this.$edit_end2(this.list)
                            this.$edit_start(this.list, beforeItem, beforeIndex)
                            this.focusStart('number'+beforeIndex)
                        }
                    }
                }

                // # 마지막 지우기
                else if (key == "←") {
                    console.log("HI")
                    if (editItem.edit_operator) {
                        console.log("HIHIHI")
                        this.insertAtCursor(key)
                    } else {
                        // console.log("HI")
                        // if(editIndex == this.list.length - 1){
                        //     console.log("HI")
                        //     this.list.splice(editIndex, 1)
                        //     this.$edit_end2(this.list)
                        //     this.$edit_start(this.list, beforeItem, beforeIndex)
                        //     this.focusStart('number'+beforeIndex)
                        // }

                        this.list.splice(editIndex, 1)
                        this.$edit_end2(this.list)
                        this.$edit_start(this.list, beforeItem, beforeIndex)
                        this.focusStart('number'+beforeIndex)
                    }
                }
                else{
                    alert("수정모드에서는 이용할 수 없습니다.")
                }
            }
            else if(this.$keyType(key) == '연산자키'){
                this.insertAtCursor(key)
            }

            else if(this.$keyType(key) == '숫자키' || this.$keyType(key) == '단위키'){
                alert("연산자 수정모드에서는 이용할 수 없습니다.")
            }
        },

        // # 기본모드
        defaultMode_KeyHandler(key){
            let last = this.list.at(-1)

            // # keyList 저장
            this.$keyList_Handler(key, this.list)

            // # 처음 시작
            if(this.list.length == 0){
                // # 숫자키
                if(this.$keyType(key) == '숫자키'){
                    this.list.push({
                        operator: null,
                        number: key == "."? '0.' : key,
                        scale: null,
                        edit: false,
                        edit_number: "",
                        edit2: false,
                        edit_operator: "",
                    })
                }

                // # 단위키
                else if(this.$keyType(key) == '단위키'){
                    this.list.push({
                        operator: null,
                        number: 1,
                        scale: key,
                        edit: false,
                        edit_number: "",
                        edit2: false,
                        edit_operator: "",
                    })
                }

                // # 연산자키
                else if(this.$keyType(key) == '연산자키'){
                    this.list.push({
                        operator: key,
                        number: null,
                        scale: null,
                        edit: false,
                        edit_number: "",
                        edit2: false,
                        edit_operator: "",
                    })
                }
            }

            // # 기능키
            else if(this.$keyType(key) == '기능키'){
                // # 초기화
                if (key == "AC") {
                    this.list = []
                }

                // # 마지막 지우기
                else if (key == "←") {
                    // 숫자 O & 단위 X : 숫자 맨 끝 제거
                    if(this.list.length && last.number && last.scale == null){
                        if(this.list.length > 1){
                            // 연산자가 있으면서 2개 이상인 경우
                            if(last.number.length >= 2){
                                last.number = last.number.slice(0, -1)
                            }else{
                                last.number = null
                            }
                        }
                        // 첫 요소일 경우
                        else{
                            // 연산자가 있으면서 2개 이상인 경우
                            if(last.number.length >= 2){
                                last.number = last.number.slice(0, -1)
                            }else{
                                this.list = []
                            }
                        }
                    }

                    // 숫자 O & 단위 O : 단위 곱한 뒤 숫자 맨 끝 제거
                    else if(this.list.length && last.number && last.scale != null){
                        let multipliedNumber = last.number * this.scales[last.scale]
                        last.number = multipliedNumber.toString().slice(0,-1)
                        last.scale = null
                    }

                    // 숫자 X & 단위 X & 연산자 ? : 연산자 맨 끝 제거
                    else if(last.operator != null){
                        // 연산자가 있으면서 2개 이상인 경우
                        if(last.operator.length >= 2){
                            last.operator = last.operator.slice(0, -1)
                        }

                        // 연산자 & 숫자 둘다 없어지면 요소 통쨰로 삭제
                        else{
                            this.list.pop()
                        }
                    }
                }

                // # 저장하기
                else if (key == "=") {
                    // # 저장하기 처리
                }

                // # 탭 (서브탭 전환)
                else if (key == "Tab") {
                    if(this.$store.state.sub_tab == "000") {
                        this.$edit_end(this.list)
                        this.$store.commit('update_sub_tab', "만억조")
                        this.$store.commit('update_input_tab', "만억조")
                    }
                    else if(this.$store.state.sub_tab == "만억조") {
                        this.$edit_end(this.list)
                        this.$store.commit('update_sub_tab', "KMB")
                        this.$store.commit('update_input_tab', "KMB")
                    }
                    else if(this.$store.state.sub_tab == "KMB") {
                        this.$edit_end(this.list)
                        this.$store.commit('update_sub_tab', "000")
                        this.$store.commit('update_input_tab', "만억조")
                    }
                }
            }

            // # 숫자키
            else if(this.$keyType(key) == '숫자키'){
                // # 숫자 없음
                if(last.number == null){
                    // ) → )X
                    if(last.operator && last.operator.slice(-1) == ')') last.operator += '*'

                    if(this.list.length > 1 && last && last.operator.charAt(0) == "("){
                        last.operator = "*" + last.operator
                    }

                    last.number = key == "."? "0." : key
                }

                // # 숫자 있음
                else{
                    // 15자 이상
                    if(last.number.length > 15){
                        return
                    }

                    // # 단위 없음
                    if(last.scale == null){
                        // # 만억조
                        if(this.$store.state.input_tab == "만억조"){
                            // # 1만 이상
                            if(last.number > 10000 && this.$lastNumberKeySet().length < 5){
                                let test = this.$math.subtract(last.number, this.$lastNumberKeySet2())
                                // last.number = this.$math.add(test, this.$lastNumberKeySet())
                                if(last.number.length > 15){
                                    return
                                }else{
                                    last.number = this.$math.add(test, this.$lastNumberKeySet())
                                }
                            // # 1만 이하
                            }else{
                                // last.number = last.number + key
                                if(last.number.length > 15){
                                    return
                                }else{
                                    last.number = last.number + key
                                }
                            }
                        }

                        // # KMB
                        if(this.$store.state.input_tab == "KMB"){
                            // # 1K 이상
                            if(last.number > 1000 && this.$lastNumberKeySet().length < 4){
                                let test = this.$math.subtract(last.number, this.$lastNumberKeySet2())
                                // last.number = this.$math.add(test, this.$lastNumberKeySet())
                                if(last.number.length > 15){
                                    return
                                }else{
                                    last.number = this.$math.add(test, this.$lastNumberKeySet())
                                }
                            // # 1K 이하
                            }else{
                                // last.number = last.number + key
                                if(last.number.length > 15){
                                    return
                                }else{
                                    last.number = last.number + key
                                }
                            }
                        }
                    }

                    // # 단위 있음
                    else{
                        // # (기존 숫자 * 단위) + 마지막 숫자 키 조합
                        last.number = this.$math.add(this.$math.multiply(last.number, this.scales[last.scale]), this.$lastNumberKeySet())

                        // # 단위 초기화
                        last.scale = null
                    }
                }
            }

            // # 연산자키
            else if(this.$keyType(key) == '연산자키'){
                if(this.$store.state.main_tab == '환전' && this.exchange_list.every(item => !item.is_expanded)){
                    this.exchange_list[0].is_expanded = true
                }

                // 숫자 없음
                if(last.number == null){
                    if(last.operator == "("){
                        last.operator += key
                    }

                    // 기존 operator가 닫는 괄호인 경우 (근데 키가 곱셈인)
                    else if(last.operator == ")" && key == "*"){
                        last.operator += key
                    }

                    // 기존 operator가 닫는 괄호인 경우 (근데 키가 )인)
                    else if(last.operator == ")" && key == ")"){
                        last.operator += key
                    }

                    // 기존 operator가 닫는 괄호인 경우
                    else if(last.operator == ")"){
                        last.operator += "*"+key
                    }

                    // 기존 operator가 사칙연산인 경우
                    else{
                        // X & - : X(- 
                        if(key == "-" && last.operator.slice(-1) == "*"){
                            last.operator += "("+key
                        }

                        // / & - : /(- 
                        else if(key == "-" && last.operator.slice(-1) == "/"){
                            last.operator += "("+key
                        }

                        // 그 외에는 사칙연산 기호 교체
                        else{
                            if(last.operator.length > 1){
                                last.operator = last.operator.slice(0, -1);
                                last.operator += key
                            }else{
                                last.operator = key
                            }
                        }
                    }
                }

                // 숫자 있음
                else{
                    this.list.push({
                        operator: key,
                        number: null,
                        scale: null,
                        edit: false,
                        edit_number: "",
                        edit2: false,
                        edit_operator: "",
                    })
                }
            }

            // # 단위키
            else if(this.$keyType(key) == '단위키'){
                // # 숫자 없음
                if(last.number == null){
                    // ) → )X
                    if(last.operator && last.operator.slice(-1) == ')') last.operator += '*'

                    last.number = 1
                    last.scale = key
                }

                // # 숫자 있음
                else{
                    // # 단위 없음
                    if(last.scale == null){
                        // # 숫자 입력 없이 바로 단위 입력 시
                        if(this.$lastNumberKeySet2().length == 0){
                            // 단위값 +1
                            last.number = this.$math.add(last.number, this.scales[key])
                        }

                        // # 숫자가 가장 낮은 단위보다는 작을 때
                        if(
                            ( this.$store.state.input_tab == "만억조" && this.$math.compare(last.number, 10000) < 0 )
                            ||
                            ( this.$store.state.input_tab == "KMB" && this.$math.compare(last.number, 1000) < 0 )
                        ){
                            // 단위만 추가
                            last.scale = key
                        }

                        // # 숫자가 가장 낮은 단위보다는 클 때
                        else{
                            // # 만억조
                            if(this.$store.state.input_tab == "만억조"){
                                // # 1만 이상
                                if(last.number > 10000 && this.$lastNumberKeySet2().length < 5){
                                    let test = this.$math.subtract(last.number, this.$lastNumberKeySet2())
                                    last.number = this.$math.add(test, this.$math.multiply(this.$lastNumberKeySet2(), this.scales[key]))
                                // # 1만 이하
                                }else{
                                    // 단위값 +1
                                    last.number = this.$math.add(last.number, this.scales[key])
                                }
                            }

                            // # KMB
                            if(this.$store.state.input_tab == "KMB"){
                                // # 1K 이상
                                if(last.number > 1000 && this.$lastNumberKeySet2().length < 4){
                                    let test = this.$math.subtract(last.number, this.$lastNumberKeySet2())
                                    last.number = this.$math.add(test, this.$math.multiply(this.$lastNumberKeySet2(), this.scales[key]))
                                // # 1K 이하
                                }else{
                                    // 단위값 +1
                                    last.number = this.$math.add(last.number, this.scales[key])
                                }
                            }
                        }
                    }

                    // # 단위 있음
                    else{
                        // # 단위가 같을 시
                        if(last.scale == key){
                            last.number = this.$math.add(last.number, 1)
                        }

                        // # 단위가 다를 시
                        else{
                            // # 숫자 + 단위를 추가 입력 (X단위)
                            if(this.$isLastKeyNumberKey()){
                                // 단위값 X
                                last.number = this.$math.multiply(last.number, this.scales[key])

                                // 단위 초기화
                                last.scale = null
                            }

                            // # 숫자 + 단위를 추가 입력 (+단위)
                            else{
                                // 단위값 +1
                                last.number = this.$math.add(this.$math.multiply(last.number, this.scales[last.scale]), this.scales[key])

                                // 단위 초기화
                                last.scale = null
                            }
                        }
                    }
                }
            }
        },

        // ! 드래그 시작 (검토 필요)
        onStart() {
            // this.isDragging = true
            this.exchange_list.forEach(item => {
                item.is_expanded = false
            })
        },

        // ! 드래그 종료 (검토 필요)
        onEnd() {
            // this.isDragging = false
            // this.draggedElement = null
        }
    }
}
</script>
<style scoped>
::v-deep .v-text-field .v-input__slot::before {
    border-color: #ccc !important;
}

/* 연산자 */
::v-deep .operator--text .v-input__control .v-input__slot input {
    color: #AE7525 !important;
    font-size:18px !important;
    font-weight: 600;
}

/* 중간 수정 */
::v-deep .edit_mode .v-input__control {
    padding: 0 !important;
    margin: 0 !important;
}

::v-deep .edit_mode input {
    padding: 0 !important;
    margin: 0 !important;
}

::v-deep .edit_mode .v-input__slot {
    background-color: transparent !important;
    padding:0 !important;
    padding-bottom: 11px !important;
    margin:1px 0 !important;
    margin-top:-1px !important;
}

::v-deep .edit_mode .v-text-field__slot {
    background-color: transparent !important;
    padding: 0 !important;
    margin: 0 !important;
}

::v-deep .edit_mode .v-input__slot input {
    background-color: transparent !important;
    /* color: #0369A1 !important; */
    color: #E53935 !important;
    font-size:1.5rem !important;
    text-align: right;
    /* letter-spacing: 0.0125em !important; */
    font-weight: 400 !important;
    padding: 0 !important;
    margin: 0 !important;
}

/* 중간 수정2 */
::v-deep .edit_mode2 .v-input__control {
    padding: 0 !important;
    margin: 0 !important;
}

::v-deep .edit_mode2 input {
    padding: 0 !important;
    margin: 0 !important;
}

::v-deep .edit_mode2 .v-input__slot {
    background-color: transparent !important;
    padding:0 !important;
    padding-bottom: 11px !important;
    margin:1px 0 !important;
}

::v-deep .edit_mode2 .v-text-field__slot {
    background-color: transparent !important;
    padding: 0 !important;
    margin: 0 !important;
}

::v-deep .edit_mode2 .v-input__slot input {
    background-color: transparent !important;
    /* color: #0369A1 !important; */
    color: #E53935 !important;
    font-size:1.25rem !important;
    text-align: right;
    /* letter-spacing: 0.0125em !important; */
    font-weight: 600 !important;
    padding: 0 !important;
    margin: 0 !important;
}

::v-deep .v-btn::before {
    background-color: transparent !important;
}

.gradient-background {
	background: linear-gradient(to bottom, #f7edd5 0%, #FFFFFF 28%);
}
</style>
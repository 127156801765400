<template>
    <div
        class="pa-4"
    >
        <v-sheet>
            HI
        </v-sheet>

        <!-- 버튼 -->
        <v-btn
            @click="test()"
        >
            테스트
        </v-btn>
    </div>
</template>

<script>
export default {
    data: () => ({
        apiKey: "IgNxtuY7eTiaLTKryfp5FAnZjebkd6Oy"
    }),

    methods: {
        test(){
            console.log("HI")

            // this.$http.get("https://www.koreaexim.go.kr/site/program/financial/exchangeJSON?authkey=IgNxtuY7eTiaLTKryfp5FAnZjebkd6Oy&searchdate=20240814&data=AP01")
            // .then((res) => {
            //     console.log(res)
            // })

            this.$http.get("/exchange_api/site/program/financial/exchangeJSON", {
                params: {
                    authkey: "IgNxtuY7eTiaLTKryfp5FAnZjebkd6Oy",  
                    searchdate: "20240814",
                    data: "AP01"
                }
            }).then((res) => {
                console.log(res)
            })
        }
    }
}
</script>